import Image from 'next/image';
import React, { useState } from 'react';
import { MediaServiceData } from '../..';
import styles1 from './styles.module.css';
import DeleteIcon from '@mui/icons-material/Delete';

const ImagePreview = ({
  media,
  index,
  onMediaDelete,
}: {
  media: MediaServiceData;
  index: number;
  onMediaDelete: (arg1: MediaServiceData) => void;
}) => {
  const [hovering, setHovering] = useState(false);
  const [fadeAway, setFadeAway] = useState(false);

  return (
    <div className={styles1.image_container} key={index}>
      <div
        style={{
          // ...styles,
          height: '100%',
          width: '100%',
          position: 'absolute',
        }}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <Image
          src={
            media?.result
              ? media?.result
              : process.env.NEXT_PUBLIC_BACKEND_URL + '/' + media?.path
          }
          fill
          sizes="100vw"
          style={{
            borderRadius: '6px',
            objectFit: 'cover',
          }}
        />
        {hovering && (
          <div className={styles1.control_container}>
            <div className={styles1.control_manage_picture_container}>
              <div
                className={styles1.control_icon_button}
                onClick={() => onMediaDelete(media)}
                // onClick={() => handleDeleteClick(media)}
              >
                <DeleteIcon />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(ImagePreview);
