import React from 'react';
import styles from './styles.module.scss';
import Image from 'next/image';

type SPSpinnerProps = {
  fullscreen?: boolean;
  size?: number;
  maintainFullheight?: boolean;
};

const SPSpinner: React.FC<SPSpinnerProps> = ({
  fullscreen = false,
  maintainFullheight = false,
  size = 100,
}) => {
  if (fullscreen && maintainFullheight) {
    return (
      <div style={{ minHeight: 'calc(100vh - 76px)' }}>
        <div className={styles.fullscreen_container}>
          <Image
            src="/spinner.webp"
            width={size}
            height={size}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </div>
      </div>
    );
  }

  if (fullscreen) {
    return (
      <div className={styles.fullscreen_container}>
        <Image
          src="/spinner.webp"
          width={size}
          height={size}
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
      </div>
    );
  }

  return (
    <div className={styles.container_fill}>
      <Image
        src="/spinner.webp"
        width={size}
        height={size}
        style={{
          maxWidth: '100%',
          height: 'auto',
        }}
      />
    </div>
  );
};

export default SPSpinner;
