import React, { useEffect, useRef, useState } from 'react';
import { MediaServiceData } from '../..';
import styles1 from './styles.module.css';
import DeleteIcon from '@mui/icons-material/Delete';

// import { animated, useTransition, config, useSpring } from 'react-spring';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';

const VideoPreview = ({
  media,
  index,
  onMediaDelete,
}: {
  media: MediaServiceData;
  index: number;
  onMediaDelete: (arg1: MediaServiceData) => void;
}) => {
  const vidRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const handlePlayVideo = () => {
    vidRef.current.play();
    setPlaying(true);
  };

  const handleStopVideo = () => {
    vidRef.current.pause();
    setPlaying(false);
  };

  const [hovering, setHovering] = useState(false);
  const [fadeAway, setFadeAway] = useState(false);
  // const styles = useSpring({
  //   opacity: fadeAway ? 0 : 1,
  //   config: config.wobbly,
  // });

  const handleDeleteClick = (e: MediaServiceData) => {
    setFadeAway(true);
  };

  useEffect(() => {}, [fadeAway]);

  return (
    <div
      className={styles1.image_container}
      key={index}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      {hovering ? (
        <div
          className={styles1.playButton}
          onClick={() => (playing ? handleStopVideo() : handlePlayVideo())}
        >
          {playing ? (
            <PauseCircleIcon
              style={{ width: '48px', height: '48px', opacity: 0.4 }}
            />
          ) : (
            <PlayCircleFilledWhiteIcon
              style={{ width: '48px', height: '48px' }}
            />
          )}
        </div>
      ) : null}

      <div
        style={{
          // ...styles,
          height: '100%',
          width: '100%',
          position: 'absolute',
        }}
      >
        {/* <Image
          src={
            media?.file
              ? process.env.NEXT_PUBLIC_BACKEND_URL + '/' + media.file
              : process.env.NEXT_PUBLIC_BACKEND_URL + '/' + media?.path
          }
          style={{ borderRadius: '6px' }}
          layout="fill"
          objectFit="cover"
        /> */}
        <video
          ref={vidRef}
          style={{ width: '100%', height: '100%', borderRadius: '6px' }}
          crossOrigin="anonymous"
        >
          <source
            src={
              media?.result
                ? media.result
                : process.env.NEXT_PUBLIC_BACKEND_URL + '/' + media.path
            }
            type={'video/mp4'}
          />
        </video>
        {hovering && (
          <div className={styles1.control_container}>
            <div className={styles1.control_manage_picture_container}>
              <div
                className={styles1.control_icon_button}
                onClick={() => onMediaDelete(media)}
                // onClick={() => handleDeleteClick(media)}
              >
                <DeleteIcon />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(VideoPreview);
