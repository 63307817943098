import { modalBoxStyle } from '@c/Settings/LogoutModal';
import Typography2 from '@c/Typographies/Typography2';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';

const useVerificationModal = (
  verificationMessage: string,
  verificationAction: () => void,
  verificationActionButtonTitle: string = 'Да',
) => {
  const [verificationOpen, setVerificationOpen] = useState(false);

  return {
    verificationModalComponent: (
      <Modal
        open={verificationOpen}
        onClose={() => {
          setVerificationOpen(false);
        }}
      >
        <Box
          sx={modalBoxStyle}
          style={{
            padding: '30px',
            maxWidth: '400px',
            width: '80%',
          }}
        >
          <Typography2 size="l" weight="500">
            {verificationMessage}
          </Typography2>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '15px',
            }}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={() => setVerificationOpen(false)}
            >
              Отменить
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                verificationAction();
                setVerificationOpen(false);
              }}
            >
              {verificationActionButtonTitle}
            </Button>
          </div>
        </Box>
      </Modal>
    ),
    verificationOpen,
    setVerificationOpen,
  };
};

export default useVerificationModal;
