import ModalHeader from '@c/ModalComponents/ModalHeader/ModalHeader';
import { modalBoxStyle } from '@c/Settings/LogoutModal';
import { Box, CircularProgress, Modal } from '@mui/material';
import { getGirlStatistics } from 'api/statistics.service';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import styles from './statistics_module.module.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { DateTime } from 'luxon';
import Typography, { TypographyVariants } from '@c/Typographies/Typography';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

type StatisticsModuleProps = {
  girlId: string;
  open: boolean;
  onClose: () => void;
};

export type GirlStatisticReportDay = {
  girl_id: string;
  statistics_type: string;
  day: string;
  uniqueUsers: number;
  count: number;
};

export type GirlStatisticReport = GirlStatisticReportDay[];

const StatisticsModule = ({ girlId, open, onClose }: StatisticsModuleProps) => {
  const [loading, setLoading] = useState(false);
  const [girlStatistics, setGirlStatistics] = useState({});

  const fetchStatistics = async () => {
    const statistics = await getGirlStatistics(girlId);

    const finalObject = {};

    (statistics as GirlStatisticReport).map(
      (dayReport: GirlStatisticReportDay) => {
        finalObject[dayReport.day] = finalObject[dayReport.day]
          ? finalObject[dayReport.day]
          : {};
        finalObject[dayReport.day][dayReport.statistics_type] = dayReport;
      },
    );
    setGirlStatistics(finalObject);
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      fetchStatistics();
      setLoading(false);
    }
  }, [open]);

  const getLabels = () => {
    return Object.keys(girlStatistics).map((dayKey: string) => {
      return DateTime.fromMillis(Number(dayKey)).toFormat('DD.MM');
    });
  };

  const getDatasetViewCount = () => {
    const dataValues = [];
    Object.keys(girlStatistics).map((dayKey) => {
      const viewEventsForDay = girlStatistics[`${dayKey}`]['view_count'] || 0;
      // const viewEventUsers = girlStatistics[`${dayKey}`]['view_unique_users'];
      dataValues.push(viewEventsForDay);
    });
    return dataValues;
  };

  const getDatasetViewUsers = () => {
    const dataValues = [];
    Object.keys(girlStatistics).map((dayKey) => {
      // const viewEventsForDay = girlStatistics[`${dayKey}`]['view_count'] || 0;
      const viewEventUsers =
        girlStatistics[`${dayKey}`]['view_unique_users'] || 0;
      dataValues.push(viewEventUsers);
    });
    return dataValues;
  };

  const getDatasetCardShowCount = () => {
    const dataValues = [];
    Object.keys(girlStatistics).map((dayKey) => {
      const viewEventsForDay =
        girlStatistics[`${dayKey}`]['card_show_count'] || 0;
      // const viewEventUsers = girlStatistics[`${dayKey}`]['view_unique_users'];
      dataValues.push(viewEventsForDay);
    });
    return dataValues;
  };

  const getDataCardShowUsers = () => {
    const dataValues = [];
    Object.keys(girlStatistics).map((dayKey) => {
      // const viewEventsForDay = girlStatistics[`${dayKey}`]['view_count'] || 0;
      const viewEventUsers =
        girlStatistics[`${dayKey}`]['card_show_unique_users'] || 0;
      dataValues.push(viewEventUsers);
    });
    return dataValues;
  };

  const data = {
    labels: getLabels(),
    datasets: [
      {
        label: 'Просмотров в день',
        data: getDatasetViewCount(),
        borderColor: 'rgba(238, 0, 0, 0.5)',
        backgroundColor: 'rgba(238, 0, 0, 0.5)',
      },
      {
        label: 'Уникальных пользователей в день',
        data: getDatasetViewUsers(),
        borderColor: '#D1273F',
        backgroundColor: '#D1273F',
      },
    ],
  };

  const dataCard = {
    labels: getLabels(),
    datasets: [
      {
        label: 'Карточка показана раз',
        data: getDatasetCardShowCount(),
        borderColor: 'rgba(238, 0, 0, 0.5)',
        backgroundColor: 'rgba(238, 0, 0, 0.5)',
      },
      {
        label: 'Карточка показана уникальным пользователям',
        data: getDataCardShowUsers(),
        borderColor: '#D1273F',
        backgroundColor: '#D1273F',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Просмотров страницы в день',
      },
    },
  };

  const optionsCard = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Показов карточки в день',
      },
    },
  };

  const optionsAudio = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Аудио',
      },
    },
  };

  const getDatasetAudioCount = () => {
    const dataValues = [];
    Object.keys(girlStatistics).map((dayKey) => {
      const viewEventsForDay =
        girlStatistics[`${dayKey}`]['audio_intro_play_count'] || 0;
      // const viewEventUsers = girlStatistics[`${dayKey}`]['view_unique_users'];
      dataValues.push(viewEventsForDay);
    });
    return dataValues;
  };

  const getDatasetAudioUsers = () => {
    const dataValues = [];
    Object.keys(girlStatistics).map((dayKey) => {
      // const viewEventsForDay = girlStatistics[`${dayKey}`]['view_count'] || 0;
      const viewEventUsers =
        girlStatistics[`${dayKey}`]['audio_intro_play_unique_users'] || 0;
      dataValues.push(viewEventUsers);
    });
    return dataValues;
  };

  const dataAudio = {
    labels: getLabels(),
    datasets: [
      {
        label: 'Прослушано аудио',
        data: getDatasetAudioCount(),
        borderColor: 'rgba(238, 0, 0, 0.5)',
        backgroundColor: 'rgba(238, 0, 0, 0.5)',
      },
      {
        label: 'Прослушано уник. пользователями',
        data: getDatasetAudioUsers(),
        borderColor: '#D1273F',
        backgroundColor: '#D1273F',
      },
    ],
  };

  const getEventStatistics = (type: string, unique = false) => {
    return Object.keys(girlStatistics).map((dayIso) => {
      const dayReportForType = girlStatistics[dayIso][type];
      if (dayReportForType) {
        return unique
          ? (dayReportForType as GirlStatisticReportDay).uniqueUsers
          : (dayReportForType as GirlStatisticReportDay).count;
      } else {
        return 0;
      }
    });
  };
  const processLabels = () => {
    return Object.keys(girlStatistics).map((dayIso) =>
      DateTime.fromISO(dayIso).toFormat('dd.MM'),
    );
  };

  const chart_data_card = {
    labels: processLabels(),
    //   girlStatistics.map((dayReport: GirlStatisticReportDay) =>
    //   DateTime.fromISO(dayReport.day).toFormat('DD'),
    // ),
    datasets: [
      {
        label: 'Показов карточек',
        data: getEventStatistics('card_show'),
        borderColor: 'rgba(238, 0, 0, 0.5)',
        backgroundColor: 'rgba(238, 0, 0, 0.5)',
      },
      {
        label: 'Уникальных показов карточек',
        data: getEventStatistics('card_show', true),
        borderColor: 'rgba(238, 0, 0, 0.2)',
        backgroundColor: 'rgba(238, 0, 0, 0.2)',
      },
    ],
  };

  const chart_data_view = {
    labels: processLabels(),
    //   girlStatistics.map((dayReport: GirlStatisticReportDay) =>
    //   DateTime.fromISO(dayReport.day).toFormat('DD'),
    // ),
    datasets: [
      {
        label: 'Просмотров Страницы',
        data: getEventStatistics('view'),
        borderColor: 'rgba(238, 0, 0, 0.5)',
        backgroundColor: 'rgba(238, 0, 0, 0.5)',
      },
      {
        label: 'Уникальных просмотров страницы',
        data: getEventStatistics('view', true),
        borderColor: 'rgba(238, 0, 0, 0.2)',
        backgroundColor: 'rgba(238, 0, 0, 0.2)',
      },
    ],
  };

  const chart_data_phone = {
    labels: processLabels(),
    //   girlStatistics.map((dayReport: GirlStatisticReportDay) =>
    //   DateTime.fromISO(dayReport.day).toFormat('DD'),
    // ),
    datasets: [
      {
        label: 'Показов Телефона',
        data: getEventStatistics('show_phone'),
        borderColor: 'rgba(238, 0, 0, 0.5)',
        backgroundColor: 'rgba(238, 0, 0, 0.5)',
      },
      {
        label: 'Показов телефона уникальных',
        data: getEventStatistics('show_phone', true),
        borderColor: 'rgba(238, 0, 0, 0.2)',
        backgroundColor: 'rgba(238, 0, 0, 0.2)',
      },
    ],
  };

  const chart_opt = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Просмотров страницы в день',
      },
    },
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={modalBoxStyle}
        style={{
          padding: '25px',
          maxWidth: '600px',
          maxHeight: '80%',
          width: '100%',
        }}
      >
        <ModalHeader title="Статистика Анкеты" onClose={onClose} />
        <div style={{ overflowY: 'auto' }}>
          <Line
            data={chart_data_card}
            options={{
              ...chart_opt,
              plugins: {
                ...chart_opt.plugins,
                title: {
                  ...chart_opt.plugins.title,
                  text: 'Показы карточек',
                },
              },
            }}
            style={{ maxWidth: '600px', width: '100%' }}
          />
          <Line
            data={chart_data_view}
            options={{
              ...chart_opt,
              plugins: {
                ...chart_opt.plugins,
                title: {
                  ...chart_opt.plugins.title,
                  text: 'Просмотры страницы',
                },
              },
            }}
            style={{ maxWidth: '600px', width: '100%' }}
          />
          <Line
            data={chart_data_phone}
            options={{
              ...chart_opt,
              plugins: {
                ...chart_opt.plugins,
                title: {
                  ...chart_opt.plugins.title,
                  text: 'Показов телефона',
                },
              },
            }}
            style={{ maxWidth: '600px', width: '100%' }}
          />
          {/* <Line
            data={dataCard}
            options={optionsCard}
            style={{ maxWidth: '600px', width: '100%' }}
          />
          <Line
            data={dataAudio}
            options={optionsAudio}
            style={{ maxWidth: '600px', width: '100%' }}
          /> */}
        </div>
        <div>{loading && <CircularProgress />}</div>
      </Box>
    </Modal>
  );
};

export default StatisticsModule;
