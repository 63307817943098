import SPSpinner from '@c/DisplayComponents/SPSpinner';
import ModalHeader from '@c/ModalComponents/ModalHeader/ModalHeader';
import { NotificationContext } from '@c/notification/NotificationProvider';
import { modalBoxStyle } from '@c/Settings/LogoutModal';
import { MediaServiceData } from '@c/Settings/SettingsManagePublishings/CreateNewPublishingModal';
import { validateImage } from '@c/Settings/SettingsManagePublishings/CreateNewPublishingModal/Step3/validation.utils';
import { GirlData } from '@c/types/girl.interface';
import Typography2 from '@c/Typographies/Typography2';
import { Button, CircularProgress, Modal } from '@mui/material';
import { Box } from '@mui/system';
import { axiosInstance } from 'api/axios.interceptors';
import { requestVerification } from 'api/girl.service';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NotificationType } from 'shared/interfaces/notification.interface';
import { updateSinglePreview } from 'store/actions/user.actions';
import { AppDispatch } from 'store/store';
import { theme } from 'theme/theme';
import ImagePreview from './ImagePreview';
import styles from './styles.module.scss';
import VideoPreview from './VideoPreview';

type VerificationModalProps = {
  open: boolean;
  onClose: () => void;
  girl: GirlData;
};

const VerificationModal: React.FC<VerificationModalProps> = ({
  open,
  onClose,
  girl,
}) => {
  const [mediaState, setMediaState] = useState([]);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const DragNDropImage = () => {
    return (
      <div
        className={styles.inputImageContainer + ' ' + styles.image}
        onDragOver={(e) => stopReload(e)}
        onDrop={(e) => handleDragEvent(e)}
      >
        Перетащите или{' '}
        <Button
          size="small"
          variant="contained"
          component="label"
          color="primary"
          style={{ width: 'fit-content' }}
        >
          <input
            type="file"
            accept="image/*, video/*"
            multiple
            style={{ display: 'none' }}
            onChange={(e) => handleBrowseEvent(e)}
          />
          Загрузите
        </Button>
      </div>
    );
  };

  const handleFileUpload = (e) => {
    stopReload(e);
    e.preventDefault();
    setLoadingUpload(true);

    try {
      let files: FileList = e?.dataTransfer?.files
        ? e.dataTransfer.files
        : e.target.files;

      let newMedias: Array<MediaServiceData> = [];
      const currentCount = mediaState?.filter(
        (media) => media.type === 'image' || media.type === 'video',
      )?.length;
      const freeSlots = 12 - currentCount;
      let taken = 0;
      for (let i = 0; i < files.length; i++) {
        if (files[i].size < 200e6) {
          const isVideo = files[i].type.includes('video');
          let reader = new FileReader();
          //ToDo important do not
          reader.onloadend = async () => {
            const newMedia = {
              name: files[i].name,
              order:
                mediaState?.filter(
                  (media) => media.type === 'image' || media.type === 'video',
                ).length + newMedias.length,
              file: files[i],
              lastModified: files[i].lastModified,
              size: files[i].size,
              type: isVideo ? 'video' : 'image',
              result: reader.result,
            };
            if (!isVideo) {
              const valid = await validateImage(reader.result);
              if (!valid) {
                notify(
                  NotificationType.Error,
                  'Слишком узкая или слишком широкая картинка',
                  'Ошибка загрузки',
                );
                setLoadingUpload(false);
                return false;
              }
            }

            if (taken < freeSlots) {
              taken++;
              newMedias.push(newMedia);
            }
            if (
              newMedias.length === files.length ||
              newMedias.length === freeSlots
            ) {
              // setError('');
              setLoadingUpload(false);
              setMediaState([...mediaState, ...newMedias]);
            }
          };
          reader.readAsDataURL(files[i]);
        } else {
          notify(NotificationType.Error, 'Файл больше 50мб', 'Ошибка загрузки');
        }
      }
    } catch (e) {
      notify(
        NotificationType.Error,
        'Проверьте формат файла',
        'Ошибка загрузки',
      );
    }
  };

  const handleDelete = (file: MediaServiceData) => {
    setMediaState([
      ...mediaState?.filter(
        (media) =>
          (media.type === 'image' || media.type === 'video') &&
          file.order !== media.order,
      ),
    ]);
  };

  const handleBrowseEvent = (e) => {
    stopReload(e);
    handleFileUpload(e);
  };

  const stopReload = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEvent = (e) => {
    handleFileUpload(e);
  };
  const notify = useContext(NotificationContext);
  const dispatch: AppDispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const request = async () => {
    setLoading(true);
    await requestVerification(girl.id, mediaState)
      .then(() => {
        dispatch(updateSinglePreview(girl.id));
        onClose();
      })
      .catch(() => notify(NotificationType.Error, 'Уже запрошено'))
      .finally(() => {
        notify(
          NotificationType.Success,
          'Анкета отправлена на подтверждение, это не займет много времени!',
          'Отправлено',
        );
        setLoading(false);
      });
  };
  const id = girl?.id;
  const raw = id * 1000000;

  const handleCloseDialog = () => {
    setMediaState([]);
    onClose();
  };

  return (
    <Modal open={open} onClose={handleCloseDialog}>
      <Box
        sx={modalBoxStyle}
        style={{
          backgroundColor: 'white',
          padding: '24px',
          width: '90%',
          maxWidth: '600px',
          // display: 'flex',
          // flexDirection: 'column',
          // gap: '40px',
          maxHeight: '90vh',
        }}
      >
        <ModalHeader
          title={`Подтвердить Анкету ${girl?.name}`}
          onClose={handleCloseDialog}
        />
        <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>
          <p>
            Ваш код: <strong>{raw.toString(36)}</strong>
            <br />
            Что нужно для успешного прохождения верификации:
            <ul>
              <li style={{ marginBottom: '4px' }}>
                Запишите короткое видео, в котором вы показываете ваш код,
                написанный от руки на листочке бумаги
              </li>
              <li style={{ marginBottom: '4px' }}>
                Убедитесь, что видео ясно демонстрирует, что человек
                представленный на фото в анкете является тем же человеком на
                видео
              </li>
              <li>
                Дополнительно! Если фотографии в анкете с размытым лицом,
                предоставьте их оригинал без размытого лица. Эти фотографии
                нигде использоваться не будут и служат только для подтверждения
              </li>
              <li>
                Если Вы удалите/добавите фотографии в анкете, нужно будет пройти
                повторную верификацию анкеты
              </li>
            </ul>
            Если у вас есть какие-либо вопросы - воспользуйтесь разделом
            поддержка
          </p>
        </div>
        <div className={styles.section_header}>
          <Typography2 weight="600" size="l">
            Медиафайлы подтверждения
          </Typography2>
          <div
            className={styles.section_line}
            style={{ backgroundColor: theme.palette.lightPurple.main }}
          />
          <Typography2 weight="400" size="m">
            {
              mediaState?.filter(
                (media) => media.type === 'image' || media.type === 'video',
              )?.length
            }{' '}
            / 12
          </Typography2>
        </div>
        <div className={styles.media_state_container}>
          {mediaState
            ?.filter(
              (media: MediaServiceData) =>
                media.type === 'image' || media.type === 'video',
            )
            .map((image, index) =>
              image.type === 'image' ? (
                <ImagePreview
                  media={image}
                  index={index}
                  key={index}
                  onMediaDelete={handleDelete}
                />
              ) : (
                <VideoPreview
                  media={image}
                  index={index}
                  key={index}
                  onMediaDelete={handleDelete}
                />
              ),
            )}
          {loadingUpload && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <SPSpinner size={64} />
            </div>
          )}
          {mediaState?.filter(
            (media) => media.type === 'image' || media.type === 'video',
          )?.length < 12 && <DragNDropImage />}
        </div>
        <div className="flex-end">
          <Button
            variant="contained"
            onClick={request}
            disabled={mediaState.length === 0}
          >
            {loading ? (
              <CircularProgress size={26} color="secondary" />
            ) : (
              'Запросить Верификацию'
            )}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default VerificationModal;
