import VerificationModal from '@c/GirlComponents/Cards/CardVertical/VerificationModal';
import EditMenu, { ManageGirlModals } from '@c/GirlComponents/Cards/EditMenu';
import StatisticsModule from '@c/Statistics/StatisticsModule';
import { GirlData } from '@c/types/girl.interface';
import useVerificationModal from '@c/Dialogs/VerificationModal/VerificationModal/VerificationModal';
import { axiosInstance } from 'api/axios.interceptors';
import { girlPause, girlUnpause } from 'api/girl.service';
import React, { useContext, useEffect } from 'react';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getFullGirlInfo, setIsManaging } from 'store/actions/girls.actions';
import {
  updateSinglePreview,
  updateUserBalance,
} from 'store/actions/user.actions';
import { AppDispatch } from 'store/store';
import { NotificationContext } from '@c/notification/NotificationProvider';
import { NotificationType } from 'shared/interfaces/notification.interface';
import { checkIsPaidForToday } from 'api/user.service';
import { latinize } from '../shared/search.utils';

export const priceFormatter = (price: string | number | undefined) => {
  if (!price) return '- ₽';
  return Number(price).toLocaleString().replace(/\s+/g, '.') + '₽';
};

export const priceFormatterKs = (price: string | number | undefined) => {
  if (!price) return '-';
  return String(price).replace('000', 'k');
};

export const useGirlControls = (onPaginate: () => void) => {
  const [girlSelected, setGirlSelected] = useState<
    Partial<GirlData> | undefined
  >(undefined);
  const [openedModal, setOpenedModal] = useState<ManageGirlModals | undefined>(
    undefined,
  );

  const dispatch: AppDispatch = useDispatch();

  const [anchorEl, setAnchorEl] =
    useState<React.MouseEvent<HTMLButtonElement> | null>(null);

  const [loading, setLoading] = useState(false);

  const handlePause = async () => {
    try {
      setLoading(true);
      await girlPause(String(girlSelected?.id));
    } catch {
      setLoading(false);
      notify(
        NotificationType.Error,
        'Проверьте свой баланс или подтвердите анкету',
        'Ошибка',
      );
    }
    setTimeout(() => {
      dispatch(updateSinglePreview(girlSelected?.id)).then(() =>
        setLoading(false),
      );
    });
  };
  const notify = useContext(NotificationContext);
  const handlePlay = async () => {
    try {
      setLoading(true);
      const paid = await checkIsPaidForToday(girlSelected?.id);
      if (paid) {
        await girlUnpause(String(girlSelected?.id));
        await dispatch(updateUserBalance());
        dispatch(updateSinglePreview(girlSelected?.id)).then(() =>
          setLoading(false),
        );
      } else {
        setLoading(false);
        confirmPlay.setVerificationOpen(true);
      }
    } catch {
      setLoading(false);
      notify(
        NotificationType.Error,
        'Проверьте свой баланс или подтвердите анкету',
        'Ошибка',
      );
    }
  };

  const handleOpenModal = (
    modal: ManageGirlModals,
    girlSelected?: GirlData,
  ) => {
    if (girlSelected) {
      setGirlSelected(girlSelected);
    }
    handleMenuClose();
    if (modal === ManageGirlModals.edit) return handleEditSelect();

    if (modal === ManageGirlModals.delete) return handleDeleteGirl();
    if (modal === ManageGirlModals.play) return handlePlay();
    if (modal === ManageGirlModals.pause) return handlePause();
    return setOpenedModal(modal);
  };

  const handleCloseModal = () => {
    setOpenedModal(undefined);
  };

  const handleEditSelect = async () => {
    await dispatch(getFullGirlInfo(girlSelected?.id));
    dispatch(setIsManaging(true));
  };

  const verificationConfirmationDialog = useVerificationModal(
    'Вы уверены, что хотите удалить анкету?',
    async () => {
      await axiosInstance.delete('/girl/' + girlSelected?.id, {
        withCredentials: true,
      });
      onPaginate();
    },
  );

  const confirmPlay = useVerificationModal(
    'Сегодняшний день не оплачен. Запуск анкеты спишет с вашего аккаунта сумму равную одному дню. Вы уверены, что хотите включить анкету?',
    async () => {
      setLoading(true);
      await girlUnpause(String(girlSelected?.id));
      await dispatch(updateUserBalance());
      dispatch(updateSinglePreview(girlSelected?.id)).then(() =>
        setLoading(false),
      );
    },
  );

  const handleDeleteGirl = () => {
    verificationConfirmationDialog.setVerificationOpen(true);
  };

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    girl: GirlData,
  ) => {
    setGirlSelected(girl);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const modalComponents = useMemo(
    () => (
      <>
        <EditMenu
          anchorEl={anchorEl}
          handleClose={handleMenuClose}
          onClick={handleOpenModal}
          verification={girlSelected?.verified}
          paused={girlSelected?.status !== 'active'}
        />
        <StatisticsModule
          girlId={girlSelected?.id}
          open={openedModal === ManageGirlModals.statistics}
          onClose={handleCloseModal}
        />
        {verificationConfirmationDialog.verificationModalComponent}
        {confirmPlay.verificationModalComponent}
        <VerificationModal
          open={openedModal === ManageGirlModals.verify}
          onClose={handleCloseModal}
          girl={girlSelected}
        />
      </>
    ),
    [anchorEl, girlSelected, openedModal, verificationConfirmationDialog],
  );

  return { modalComponents, handleMenuOpen, handleOpenModal, loading };
};

export const getGirlUrl = (girl: GirlData) => {
  return `/girls/${girl.type}/${latinize(girl.locationCity)}/${latinize(
    girl.locationUnit,
  )}/${girl.id}-${latinize(girl.name)}`;
};
