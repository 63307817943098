import { Divider, ListItemIcon, Menu, MenuItem } from '@mui/material';
import React from 'react';
import GearIcon from '@a/../../assets/settingsIcons/settings_white.svg';
import VerifyIcon from '@a/../../assets/girlIcons/verify.svg';
import { theme } from 'theme/theme';
import EditIcon from '@mui/icons-material/Edit';
import PauseIcon from '@mui/icons-material/Pause';
import PlayIcon from '@mui/icons-material/PlayArrow';
import BarChartIcon from '@mui/icons-material/BarChart';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type EditMenuProps = {
  anchorEl: any;
  handleClose: () => void;
  onClick: (arg1: ManageGirlModals) => void;
  verification: boolean;
  paused: boolean;
};

export enum ManageGirlModals {
  edit = 'edit',
  delete = 'delete',
  statistics = 'statistics',
  verify = 'verify',
  pause = 'pause',
  play = 'play',
}

const EditMenu = ({
  anchorEl,
  handleClose,
  onClick,
  paused,
}: EditMenuProps) => {
  const open = Boolean(anchorEl);
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem
        onClick={() => {
          onClick(ManageGirlModals.edit);
        }}
      >
        <ListItemIcon>
          <EditIcon
            width={20}
            height={20}
            fill={theme.palette.textTransluent.main}
          />
        </ListItemIcon>
        Редактировать
      </MenuItem>
      {paused ? (
        <MenuItem
          onClick={() => {
            onClick(ManageGirlModals.play);
          }}
        >
          <ListItemIcon>
            <PlayIcon
              width={20}
              height={20}
              fill={theme.palette.textTransluent.main}
            />
          </ListItemIcon>
          Включить
        </MenuItem>
      ) : (
        <MenuItem
          onClick={() => {
            onClick(ManageGirlModals.pause);
          }}
        >
          <ListItemIcon>
            <PauseIcon
              width={20}
              height={20}
              fill={theme.palette.textTransluent.main}
            />
          </ListItemIcon>
          Пауза
        </MenuItem>
      )}
      <Divider />
      <MenuItem
        onClick={() => {
          onClick(ManageGirlModals.delete);
        }}
      >
        <ListItemIcon>
          <DeleteIcon
            width={20}
            height={20}
            fill={theme.palette.textTransluent.main}
          />
        </ListItemIcon>
        Удалить
      </MenuItem>
    </Menu>
  );
};

export default EditMenu;
